
/**
 * Мобильная модалка выезжающая сверху, которую можно свайпить
 *
 * @version 1.0.1
 * @displayName TopSheet
 */
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

export default {
    name: 'TopSheet',

    props: {
        transparentBg: {
            type: Boolean,
            default: false,
        },

        needLockBody: {
            type: Boolean,
            default: true,
        },

        topPad: {
            type: Number,
            default: 0,
        },

        cardStylesProp: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            startBottomPosition: null,
            bottomPosition: null,

            // Взаимодействие с DOM
            rect: null,
            offsetHeight: null,
            observer: null,

            isMoving: false,
        };
    },

    computed: {
        cardStyles() {
            return {
                bottom: this.bottomPosition !== null ? `${this.bottomPosition}` : '',
                top: `${this.topPad}px`,
                ...this.$props.cardStylesProp,
            };
        },
    },

    watch: {
        isMoving(value) {
            if (value) {
                this.$emit('move');
            }
        },

        // При изменении высоты контента внутри карточки меняем её положение
        offsetHeight(value, oldValue) {
            this.rect = this.$refs.card.getBoundingClientRect();

            if (this.bottomPosition < 0) {
                this.bottomPosition = 0;
                return;
            }

            // Если высота контента меньше, чем высота экрана
            if (value < this.rect.height) {
                this.bottomPosition = window.innerHeight - this.rect.bottom;
                return;
            }

            if (value && oldValue) {
                this.bottomPosition -= value - oldValue;
            }
        },
    },

    mounted() {
        if (this.$refs.card) {
            // Подписываемся на изменение размеров карточки
            this.observer = new MutationObserver(() => {
                this.$nextTick(() => {
                    this.offsetHeight = this.$refs.sheetContents.scrollHeight;
                });
            });

            const config = { attributes: true, childList: true, subtree: true };

            this.observer.observe(this.$refs.card, config);

            // Получаем начальное положение карточки
            // Таймаут нужен, чтобы карточка появиться после анимации открытия модалки
            setTimeout(() => {
                this.rect = this.$refs.card.getBoundingClientRect();

                // Если высота карточки больше, чем высота экрана, то устанавливаем отступ сверху - 0
                if (this.rect.height > window.innerHeight) {
                    this.bottomPosition = 0;
                } else {
                    this.bottomPosition = window.innerHeight - this.rect.bottom;
                }
            }, 500);
        }

        if (this.needLockBody) {
            disablePageScroll();
        }
    },

    beforeDestroy() {
        this.observer.disconnect();

        if (this.needLockBody) {
            enablePageScroll();
        }
    },

    methods: {
        handleMoveStart(event) {
            this.isMoving = true;
            if (this.bottomPosition === null) {
                this.startBottomPosition = window.innerHeight - event.changedTouches?.[0].clientY - 16;
            } else {
                this.startBottomPosition = this.bottomPosition;
            }
        },

        handleMove(event) {
            // Получаем y координату тача и вычитаем 16 пикселей,
            // чтобы визуально карточка не опаздывала за пальцем
            this.bottomPosition = window.innerHeight - event.changedTouches?.[0].clientY - 16;
        },

        handleMoveEnd() {
            this.isMoving = false;

            // Высота area - 30px
            // Если начальное положение в пределах area,
            // А новое значение меньше 0 (пользователь свайпнул вверх),
            // То возвращаем карточку в начальное положение
            if (this.startBottomPosition < 30 - 16 && this.bottomPosition < -16) {
                this.bottomPosition = 0;
                return;
            }

            // Сравниваем текущее положение карточки с начальным
            // Если карточка ниже чем была, то возвращаем её в начальное положение
            if (this.bottomPosition < this.startBottomPosition) {
                this.bottomPosition = this.startBottomPosition;
                return;
            }

            // Детектим свайп вверх + положение карточки относительно начальной точки
            if (this.bottomPosition > this.startBottomPosition) {
                this.$emit('close');
            }
        },

        handleClose(closeAll = false) {
            this.bottomPosition = window.innerHeight - this.rect.bottom + this.rect.height;
            if (closeAll === true) {
                this.$modal.close();
            }
            setTimeout(() => {
                this.$emit('close');
            }, 200);
        },
    },
};
