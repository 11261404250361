export const specialTag = {
    isImportant: {
        label: 'Важное',
        value: 'vazhnoe',
        icon: 'lightning',
    },
    isNew: {
        label: 'Новое',
        value: null,
        icon: 'lightning',
    },
    isExclusive: {
        label: 'Эксклюзив',
        value: null,
        icon: 'lightning',
    },
    isPartner: {
        label: 'Реклама',
        value: null,
        icon: 'ads',
    },
};
