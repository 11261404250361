export const MEDIA_TYPE_NAMES = {
    1: 'Новости',
    3: 'Статьи',
    6: 'Колонки',
    7: 'YouTube',
};

export const MEDIA_TYPE_SLUGS = {
    1: 'news',
    3: 'articles',
    6: 'columns',
    7: 'youtube',
};
