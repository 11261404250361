import { formatDateTime } from '@@/common/assets/js/utils/date-time-utils';

export function convertToUnit(str, unit = 'px') {
    // eslint-disable-next-line no-eq-null
    if (str == null || str === '') {
        return undefined;
    } else if (isNaN(Number(str))) {
        return String(str);
    } else {
        return `${Number(str)}${unit}`;
    }
}

export function bytesToKB(bytes) {
    if (!bytes) {
        return 0;
    }

    const kilobytes = bytes / 1024;
    return kilobytes.toFixed(2);
}

export function formatDate(date) {
    if (date) {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = new Date(date).toLocaleDateString('ru-RU', options);
        return formattedDate;
    }
}

export function plural(num, postfixes) {
    if (num === 0) {
        return postfixes[2];
    }

    if (!num) {
        console.warn('[plural] Wrong Number ', num);
        return '';
    }

    let n = Math.abs(num);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return postfixes[2];
    }
    n %= 10;
    if (n === 1) {
        return postfixes[0];
    }
    if (n >= 2 && n <= 4) {
        return postfixes[1];
    }
    return postfixes[2];
}

export function numberToDuration(readTime) {
    const hours = Math.floor(readTime / 60);
    return `${hours > 0 ? hours + 'ч' : ''} ${readTime % 60}мин`;
}

function isToday(date) {
    const now = new Date();
    return formatDateTime(date, '$j $b $y') === formatDateTime(now, '$j $b $y');
}

function isYesterday(date) {
    const now = new Date();
    const yesterday = new Date(now.getTime() - 24 * 60 * 60 * 1000);
    return formatDateTime(date, '$j $b $y') === formatDateTime(yesterday, '$j $b $y');
}

function getMinutesAgo(date) {
    const now = new Date();
    return Math.floor((now - date) / 60000);
}

function formatTime(date) {
    return formatDateTime(date, '$H:$I');
}

function formatFullDate(date) {
    return formatDateTime(date, '$j $b, $H:$I');
}

export function getPubDate(date) {
    const pubDate = new Date(date);

    if (isToday(pubDate)) {
        const minutesAgo = getMinutesAgo(pubDate);
        if (minutesAgo < 60) {
            return `${minutesAgo} минут назад`;
        } else {
            return formatTime(pubDate);
        }
    } else if (isYesterday(pubDate)) {
        return formatFullDate(pubDate);
    } else {
        return formatFullDate(pubDate);
    }
}
