export const navigations = [
    {
        label: 'Главная',
        to: '/',
        icon: 'home-2'
    },
    {
        label: 'Медиа',
        to: '/media',
        icon: 'newspaper'
    },
    {
        label: 'Работа',
        to: '/job/vacancy',
        icon: 'breifcase-2'
    },
    {
        label: 'Меню',
        icon: 'apps'
    },
];
