
// Components
import VTag from 'portal/components/ui/tag/VTag.vue';
import VIcon from 'portal/components/ui/icon/VIcon.vue';

export default {
    name: 'InsightItem',
    components: {
        VIcon,
        VTag,
    },

    props: {
        item: {
            type: Object,
            default: () => ({}),
        },

        wasVisible: {
            type: Boolean,
            default: false,
        },

        isNew: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        duration() {
            const hours = Math.floor(this.item.read_time / 60);
            return `${hours > 0 ? hours + 'ч' : ''} ${this.item.read_time, this.item.read_time % 60}м`;
        },

        deviceIsMobile() {
            return this.$deviceIs.mobile;
        },

        vTagColorProps() {
            const tagStylesProps = {
                color: this.deviceIsMobile ? 'custom' : 'blue',
                iconName: this.deviceIsMobile ? 'flashlight' : '',
                label: this.deviceIsMobile ? 'Новое' : 'NEW',
            };
            return tagStylesProps;
        },
    },
};
