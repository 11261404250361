
// Directives
import resize from '~/directives/resize';
// Utils
import { throttle } from '@@/common/assets/js/utils/common-utils';
// Components
import VButtonNew from '../../ui/button/VButtonNew.vue';
import VDropdown from '../../ui/dropdown/VDropdown.vue';

const IMPORTANT_TAG_SLUG = 'vazhnoe';
const MAIN_TAG_LABEL = 'Общее';
const IMPORTANT_TAG_LABEL = 'Важное';
const FEED_TEXT = 'Лента';
const MORE_TEXT = 'Еще';

const MEDIA_PAGE = '/media';

export default {
    name: 'CategoryTags',

    directives: {
        resize,
    },

    components: {
        VButtonNew,
        VDropdown,
    },

    props: {
        tags: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            IMPORTANT_TAG_SLUG,
            FEED_TEXT: FEED_TEXT,
            MORE_TEXT: MORE_TEXT,
            numberVisibleTags: 0,
            isLoading: true,
        };
    },

    computed: {
        startTags() {
            return [
                {
                    id: -1,
                    label: MAIN_TAG_LABEL,
                    slug: this.activeTag ? MEDIA_PAGE : '',
                },
                {
                    id: -2,
                    label: IMPORTANT_TAG_LABEL,
                    slug: IMPORTANT_TAG_SLUG,
                },
            ];
        },

        allTags() {
            const filteredCategories = this.tags.filter(item => item.slug !== IMPORTANT_TAG_SLUG);
            return [...this.startTags, ...filteredCategories];
        },

        visibleTags() {
            return [...this.allTags].slice(0, this.numberVisibleTags);
        },

        hiddenTags() {
            return [...this.allTags].slice(this.numberVisibleTags);
        },

        isTablet() {
            return this.$deviceIs.mobile || this.$deviceIs.tablet;
        },

        activeTag() {
            return this.$route?.params?.id || '';
        },
    },

    methods: {
        throttle,

        getTagUrl(tag) {
            if (tag.slug === '') {
                return '';
            }

            if (tag.slug === MEDIA_PAGE) {
                return tag.slug;
            }

            return `/media/${tag.slug}`;
        },

        setContainedTags() {
            if (this.$deviceIs.mobile) {
                return;
            }

            this.isLoading = true;
            this.numberVisibleTags = this.allTags.length;

            this.$nextTick(() => {
                this.$refs.tag?.forEach(tag => {
                    if (tag.$el.offsetTop > 0) {
                        this.numberVisibleTags -= 1;
                    }
                });

                // Убираем теги с запасоп для "Ещё"
                this.numberVisibleTags -= 2;
                this.isLoading = false;
            });
        },
    },
};
