
// Utils
import { numberToDuration } from 'portal/assets/js/utils/convert-utils';
// Components
import VIcon from 'portal/components/ui/icon/VIcon.vue';
// Const
import { MEDIA_TYPE_NAMES, MEDIA_TYPE_SLUGS } from 'portal/assets/js/constants/mediaTagNames';
import { specialTag } from 'portal/assets/js/constants/mediaSpecialTagDict';

export default {
    name: 'TagList',

    components: {
        VIcon,
    },

    props: {
        isNew: {
            type: Boolean,
            default: false,
        },

        isExclusive: {
            type: Boolean,
            default: false,
        },

        isPartner: {
            type: String,
            default: '',
        },

        //  принимает массив или строку, если массив, то отображет первый элемент
        categories: {
            type: [String, Array],
            default: null,
        },

        bonusTag: {
            type: String,
            default: null,
        },

        type: {
            type: Number,
            default: NaN,
        },

        readTime: {
            type: Number,
            default: null,
        },

        singleColor: {
            type: Boolean,
            default: false,
        },

        size: {
            type: String,
            default: 'small',
        },
    },

    data() {
        return {
            tagList: MEDIA_TYPE_NAMES,
        };
    },

    computed: {
        isImportant() {
            if (Array.isArray(this.categories)) {
                return Boolean(this.categories?.find(item => item?.slug === specialTag.isImportant.value));
            }

            return false;
        },

        duration() {
            return numberToDuration(this.readTime) === '0мин' ? null : numberToDuration(this.readTime);
        },

        classes() {
            return {
                [this.$style._singleColor]: this.singleColor,
            };
        },

        sizeClassList() {
            return {
                [this.$style[`_${this.size}`]]: this.size,
            };
        },

        getSpecialTag() {
            const conditions = {
                isPartner: Boolean(this.isPartner),
                isImportant: this.isImportant,
                isExclusive: this.isExclusive,
                isNew: this.isNew,
            };

            const trueConditionKey = Object.entries(conditions)
                .find(([key, value]) => value === true)?.[0];

            return {
                icon: specialTag[trueConditionKey]?.icon || null,
                label: specialTag[trueConditionKey]?.label || null,
                link: specialTag[trueConditionKey]?.value ? `/media/${specialTag[trueConditionKey]?.value}` : null,
            };
        },

        currentTag() {
            if (!Array.isArray(this.categories)) {
                return this.categories;
            }

            const filteredCategories = this.categories.filter(item => item.slug !== specialTag.isImportant.value);

            return this.isPartner ? this.isPartner.toUpperCase() : filteredCategories[0]?.name;
        },

        tagSlug() {
            if (Array.isArray(this.categories) && !this.isPartner) {
                return this.categories[0]?.slug;
            }

            return null;
        },

        currentType() {
            return this.tagList[this.type] || null;
        },

        typeSlug() {
            return MEDIA_TYPE_SLUGS[this.type] || null;
        },

        showSpecialTag() {
            return this.getSpecialTag.label;
        },

        showTypeTag() {
            return [!this.isNew, !this.isExclusive, !this.isPartner, !this.isExclusive, this.currentType].every(Boolean);
        },

        showBonusTag() {
            return [!this.isNew, !this.isPartner, !this.isExclusive, this.bonusTag].every(Boolean);
        },

        showCategoryTag() {
            return [this.currentTag, this.size !== 'medium-large', !this.readTime].every(Boolean);
        },
    },
};
