
// Utils
import { mapState } from 'vuex';
import { lockBody, unlockBody } from 'portal/assets/js/utils/lock-utils';

// Components
import InsightMain from './insight/InsightMain.vue';
import TopSheet from '@/components/common/TopSheet.vue';

export default {
    name: 'TheInsight',

    components: {
        InsightMain,
        TopSheet,
    },

    props: {
        news: {
            type: Array,
            default: () => [],
        },

        initialOpen: {
            type: Boolean,
            default: false,
        },

        initialHovering: {
            type: Boolean,
            default: false,
        },

        fixedFullWidth: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isOpen: this.initialOpen,
            isHovering: false,
            lockBody: false,
            expanderContentHeight: null,
            isTop: true,
            cardStyles: {
                'border-bottom-left-radius': '20px',
                'border-bottom-right-radius': '20px',
            },
        };
    },

    computed: {
        ...mapState('header', [
            'isScrolled',
        ]),

        expanderHeight() {
            return this.isOpen ? `${this.expanderContentHeight}px` : 0;
        },

        expanderStyles() {
            const position = this.isTop ? 'bottom' : 'top';

            return {
                height: this.expanderHeight,
                [position]: '100%',
            };
        },

        classes() {
            return [
                {
                    [this.$style._open]: this.isOpen,
                    [this.$style._mini]: this.isMini,
                },
            ];
        },

        isMini() {
            return !this.isHovering && !this.isOpen && !this.fixedFullWidth;
        },
    },

    watch: {
        isOpen(newValue) {
            if (newValue) {
                this.lockBody = true;
                lockBody(false);
            } else {
                unlockBody();
                setTimeout(() => this.lockBody = false, 200);
            }
        },

        isScrolled(newValue) {
            if (!this.lockBody && newValue) {
                this.isHovering = false;
            }
        },
    },

    created() {
        this.isOpen = this.initialOpen;
        this.isHovering = this.initialHovering;
    },

    mounted() {
        const setTimeoutId = setTimeout(() => {
            this.expanderContentHeight = this.$refs['insight-main'].$el.clientHeight || 0;
            clearTimeout(setTimeoutId);
        }, 300);
    },

    methods: {
        onMouseEnter() {
            this.isHovering = true;
        },

        onMouseLeave() {
            this.isHovering = false;
        },

        onCallerClick() {
            if (!this.isOpen) {
                this.handleExtendedContentPosition();
            }

            this.isOpen = !this.isOpen;
        },

        handleExtendedContentPosition() {
            // const scrollTop = document.documentElement.scrollTop || 0;
            // const widgetScrollTop = this.$refs.widget.offsetParent.offsetTop;
            // const availableTopHeight = widgetScrollTop - scrollTop;
            //
            // this.isTop = availableTopHeight - this.expanderContentHeight >= 0;
        },
    },
};
