// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper_17rcP{z-index:100}.bg_PORPX,.wrapper_17rcP{left:0;position:fixed;top:0}.bg_PORPX{background:#000000b3;bottom:0;display:block;right:0;transition:all .3s}.bg_PORPX._transparent_DBfxQ{background:#0000}.card_XzAbG{animation:slide-up_7jcQp .3s ease-out;background-color:#fff;display:flex;flex-direction:column;height:auto;left:0;max-height:calc(var(--vh, 1vh)*100);position:fixed;top:0;width:100%}.card_XzAbG[data-state=open]{transition:bottom .3s ease-out}.bar_6eRMR{background:rgba(60,60,67,.302);border-radius:1rem;cursor:pointer;height:.4rem;margin:0 auto;width:4rem}.area_qiZIX{align-items:center;bottom:0;display:flex;height:2rem;left:0;position:absolute;right:0}.area_qiZIX .bar_6eRMR{position:relative}.area_qiZIX .bar_6eRMR:hover{cursor:grab}.area_qiZIX .bar_6eRMR:active{cursor:grabbing}.title_xiIwX{margin:4rem 2rem 0}.sheetContents_s6XmO{box-sizing:border-box;display:flex;flex-direction:column;height:auto;margin-bottom:3rem;max-height:calc(var(--vh)*100 - 4rem);overflow:auto}@keyframes slide-up_7jcQp{0%{transform:translateY(-100%)}to{transform:translateY(0)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-tablet": "5.6rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"wrapper": "wrapper_17rcP",
	"bg": "bg_PORPX",
	"_transparent": "_transparent_DBfxQ",
	"card": "card_XzAbG",
	"slide-up": "slide-up_7jcQp",
	"bar": "bar_6eRMR",
	"area": "area_qiZIX",
	"title": "title_xiIwX",
	"sheetContents": "sheetContents_s6XmO"
};
module.exports = ___CSS_LOADER_EXPORT___;
