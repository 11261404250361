
export default {
    name: 'SearchBlock',

    data() {
        return {
            search: '',
        };
    },

    computed: {
        queryParam() {
            return this.$route?.query?.q;
        },
    },

    watch: {
        $route() {
            if (process.client) {
                this.setSearchValue();
            }
        },
    },

    mounted() {
        this.setSearchValue();
    },

    methods: {
        setSearchValue() {
            this.search = this.queryParam || '';
        },

        async onSubmit() {
            const { href } = window.location;
            const url = new URL(href);
            const query = encodeURIComponent(this.search.trim());

            // Установить URL с параметром поиска
            window.location.href = `${url.origin}/search/?q=${query}`;
        },
    },
};
