function getScrollbarWidth() {
    const outer = document.createElement('div');

    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    document.body.appendChild(outer);

    const inner = document.createElement('div');
    outer.appendChild(inner);

    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
}

export function lockBody(isFixed = true, timeout = 0) {
    if (!process.browser) {
        return false;
    }
    const lock = () => {
        if (!document.body.classList.contains('scroll-lock')) {
            document.body.dataset.scrollY = window.scrollY;
            document.body.style.top = `-${document.body.dataset.scrollY}px`;
            document.body.style.paddingRight = `${getScrollbarWidth()}px`;
            document.documentElement.style.setProperty('--lock-offset', `${getScrollbarWidth()}px`);
            document.body.classList.add('scroll-lock');

            if (!isFixed) {
                document.body.classList.add('scroll-lock--not-fixed');
            }
        }
    };

    if (timeout) {
        setTimeout(lock, timeout);
    } else {
        lock();
    }
}

export function unlockBody(timeout = 0, scroll = true) {
    if (!process.browser) {
        return false;
    }

    const unlock = () => {
        if (document.body.classList.contains('scroll-lock')) {
            document.body.style.paddingRight = '';
            document.documentElement.style.setProperty('--lock-offset', '0px');
            document.body.classList.remove('scroll-lock');
            document.body.classList.remove('scroll-lock--not-fixed');

            if (scroll && document.body.dataset?.scrollY && window) {
                window.scroll({
                    top: document.body.dataset?.scrollY || 0,
                });
            }

            document.body.style.top = null;
            document.body.dataset.scrollY = null;
        }
    };

    if (timeout && scroll) {
        setTimeout(unlock, timeout);
    } else {
        unlock();
    }
}
