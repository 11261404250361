
import TagList from 'portal/components/common/TagList';
import { getPubDate } from 'portal/assets/js/utils/convert-utils';

export default {
    name: 'ContentBlockDescription',

    components: { TagList },

    props: {
        title: {
            type: String,
            required: true,
        },

        text: {
            type: String,
            default: null,
        },

        categories: {
            type: [String, Array],
            default: null,
        },

        type: {
            type: Number,
            required: true,
        },

        isNew: {
            type: Boolean,
            default: false,
        },

        isExclusive: {
            type: Boolean,
            default: false,
        },

        isPartner: {
            type: String,
            default: '',
        },

        readTime: {
            type: Number,
            default: null,
        },

        cardSize: {
            type: String,
            default: 'small',
        },

        bonusTag: {
            type: String,
            default: null,
        },

        pubDate: {
            type: String,
            default: null,
        },

    },

    computed: {
        showTagList() {
            return [
                this.isNew,
                this.isExclusive,
                this.isPartner,
                this.categories,
                this.type,
                this.readTime,
            ].some(Boolean);
        },

        classList() {
            return {
                [this.$style[`_${this.cardSize}`]]: this.cardSize,
            };
        },

        newsPubDate() {
            return getPubDate(this.pubDate);
        },

        currentBonusTag() {
            return this.cardSize === 'medium-large' ? this.newsPubDate : this.bonusTag;
        },

    },


};
