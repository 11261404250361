
import InsightItem from 'portal/components/common/insight/InsightItem';
import VScrollBox from 'portal/components/ui/scrollbox/VScrollBox.vue';

export default {
    name: 'InsightMain',
    components: { VScrollBox, InsightItem },
    props: {
        news: {
            type: Array,
            required: true,
        },

        isVisible: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            wasVisible: false,
        };
    },

    watch: {
        isVisible: {
            handler: function(val) {
                if (val && !this.wasVisible) {
                    this.wasVisible = true;
                }
            },

            immediate: true,
        },
    },
};
